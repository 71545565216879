<template>
  <div id="LandingWrapper">
    <div class="Landing">
      <div id="ImgBlur1" class="LandingBackgroundBlur" />
      <div id="ImgBlur2" class="LandingBackgroundBlur" />
      <div id="ImgBlur3" class="LandingBackgroundBlur" />
      <div id="ImgBlur4" class="LandingBackgroundBlur" />

      <LandingNavbar
        :SendRequestBusiness="
          () => {
            showRegistrationModal = true;
          }
        "
      />

      <div class="ContentBlocks">
        <div class="ContentBlock FlexBlock Flex-H">
          <div class="ContentAutoBlock FlexBlock Flex-V">
            <p class="ContentHeader">
              <span style="font-weight: 700;">Октагон</span> – платформа,<br />
              на которой ты<br />получишь реальный<br />
              опыт работы в IT

              <!-- IT Октагон — IT арена,<br />в которой ты создашь<br />свой первый
              продукт -->
            </p>
            <ul class="ContentHeaderList">
              <li>Решение нетиповых задач от клиентов</li>
              <li>Работа в дружной команде</li>
              <li>Получение практических навыков в IT</li>
            </ul>
            <ButtonGradientInside
              v-if="!user"
              ButtonText="Зарегистрироваться"
              ButtonLink="#"
              @click.native="showRegistrationModal = true"
              :NeedIcon="true"
            />

            <ButtonGradientInside
              v-if="user"
              :ButtonText="
                user &&
                user.hasOwnProperty(`organization`) &&
                user.organization.length > 0
                  ? user.organization[0].organization_name
                  : `Мой профиль`
              "
              :ButtonLink="
                user &&
                user.hasOwnProperty(`organization`) &&
                user.organization.length > 0
                  ? `/organization/profile`
                  : `/beta/profile`
              "
              :NeedIcon="true"
            />

            <div class="BackgroundImages" id="ImgLogoRectangle" />

            <div class="BackgroundImages" id="ImgLine" />

            <div class="BackgroundImages" id="ImgCircleDotted" />
            <div class="BackgroundImages" id="ImgCircle" />

            <div class="BackgroundImages" id="ImgTriangleDotted" />
            <div class="BackgroundImages" id="ImgTriangle" />

            <div class="BackgroundImages" id="ImgPlus" />

            <div class="BackgroundImages" id="ImgLogo" />
          </div>
        </div>

        <div class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Мы — твои<br />проводники в мир IT!</p>
          <div class="Benefits">
            <Benefit
              v-for="item in Benefits"
              :icon="`${item.icon}`"
              :title="item.title"
              :text="item.text"
              :key="item"
              :useThreeBlockPerLine="true"
            />
          </div>
        </div>

        <div id="PartnersBlock" class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Работаем при активной поддержке</p>
          <div class="ImagesBlock">
            <div class="PartnerImagePhoto PI_1C"></div>
            <div class="PartnerImagePhoto PI_MOIO"></div>
            <div class="PartnerImagePhoto PI_IRNITU"></div>
            <div class="PartnerImagePhoto PI_MICCIFRI"></div>
            <div class="PartnerImagePhoto PI_OBLASTMOLODIH"></div>
          </div>

          <div class="FullWidthBlock"></div>
        </div>

        <div id="OctagonToday" class="ContentBlock FlexBlock Flex-V">
          <p class="ContentTitle">Октагон сегодня</p>
          <div class="OTBlocks">
            <div class="OTBlock" style="min-width: 200px;">
              <span class="OTBHead" :key="Statistics_Showed.UsersRegistered"
                >{{ Statistics_Showed.UsersRegistered }}+</span
              >
              <span class="OTBText"
                >Регистраций <br />
                на платформе</span
              >
            </div>
            <div class="OTBlock">
              <span class="OTBHead">{{
                Statistics_Showed.ClosedPractics
              }}</span>
              <span class="OTBText">Закрытых <br />практик</span>
            </div>
            <div class="OTBlock">
              <span class="OTBHead">{{ Statistics_Showed.WorkInForus }}</span>
              <span class="OTBText">Трудоустроены <br />в ГК “Форус”</span>
            </div>
            <div class="OTBlock">
              <span class="OTBHead">{{ Statistics_Showed.OurPartners }}+</span>
              <span class="OTBText">Учебных <br />заведений</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ContentBlock FlexBlock Flex-H ContentFooter">
      <ButtonGradientInside
        v-if="user"
        :ButtonText="`Мой профиль`"
        :ButtonLink="
          user &&
          user.hasOwnProperty(`organization`) &&
          user.organization.length > 0
            ? `/organization/profile`
            : `/beta/profile`
        "
        :NeedIcon="true"
      />
      <ButtonGradientInside
        v-if="!user"
        ButtonText="Зарегистрироваться"
        ButtonLink="#"
        @click.native="showRegistrationModal = true"
        :NeedIcon="true"
      />
      <!-- <ButtonGradient
        ButtonText="Список проектов"
        ButtonLink="/project/projectsList"
        :NeedIcon="true"
      /> -->
    </div>

    <el-drawer
      v-model="openBurgerMenu"
      direction="ttb"
      modal-class="BurgerMenuButtons"
      :before-close="handleClose"
      :with-header="false"
      size="auto"
    >
      <div class="BurgerMenuButtons">
        <div class="OctagonLogo"></div>

        <router-link class="ButtonLink ButtonLinkSelected" to="/">
          Студентам
        </router-link>
        <router-link class="ButtonLink" to="/business">
          Партнёрам
        </router-link>
        <!-- <router-link class="ButtonLink" to="/gos">
          Государственному сектору
        </router-link> -->

        <ButtonGradientInside
          v-if="user"
          :ButtonText="
            user &&
            user.hasOwnProperty(`organization`) &&
            user.organization.length > 0
              ? user.organization[0].organization_name
              : `Мой профиль`
          "
          :ButtonLink="
            user &&
            user.hasOwnProperty(`organization`) &&
            user.organization.length > 0
              ? `/organization/profile`
              : `/beta/profile`
          "
          :NeedIcon="true"
        />
        <ButtonGradientInside
          v-if="!user"
          ButtonText="Зарегистрироваться"
          ButtonLink="/auth/signup-login"
          :NeedIcon="true"
        />
        <!-- <ButtonGradient
          ButtonText="Список проектов"
          ButtonLink="/project/projectsList"
          :NeedIcon="true"
        /> -->
      </div>
    </el-drawer>

    <SendRequest
      @sendFalseToParent="sendFalseToParent"
      :key="`${sendFalseToParent}${setTypeForModal}`"
      :setType="setTypeForModal"
      :showModal="showRequestModal"
    />

    <RegistrationModal
      @sendCloseRegistrationModal="sendCloseRegistrationModal"
      :openOrgRegistration="openOrgRegistration"
      :showModal="showRegistrationModal"
    />
  </div>
</template>

<script>
import { getRegisteredUsersCounter } from "@/api/landing.js";

import SendRequest from "@/components/Landing/SendRequest";
import RegistrationModal from "@/components/Landing/RegistrationModal";

import Benefit from "@/components/Landing/BenefitsBlock";
import ButtonGradientInside from "@/components/Landing/ButtonInsideGradient";
import ButtonGradient from "@/components/Landing/ButtonGradient";
import { mapGetters, mapMutations } from "vuex";
import gsap from "gsap";

import LandingNavbar from "@/components/Landing/Navbar";

import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/400.css";

import "@fontsource/montserrat/400.css";

// import "@fontsource/dm-sans";

export default {
  name: "Landing",
  components: {
    Benefit,
    ButtonGradientInside,
    ButtonGradient,
    LandingNavbar,
    RegistrationModal,
    SendRequest,
  },
  data() {
    return {
      Benefits: [
        {
          icon: "1",
          title: "Выбор<br>задачи",
          text: "Можешь выбрать  задачу от заказчика.",
        },
        {
          icon: "2",
          title: "Консультации<br>экспертов",
          text:
            "Ты получишь поддержку от наставников, которые помогут тебе в обучении и в оптимизации всех процессов.",
        },
        {
          icon: "3",
          title: "Приобретение<br>компетенций",
          text:
            "Получишь необходимые компетенции при выполнении учебных задач.",
        },
        {
          icon: "4",
          title: "Выполнение<br>задачи",
          text: "Выполнишь задачу от заказчика при помощи наставника.",
        },
        // {
        //   icon: "5",
        //   title: "Внедрение у<br>заказчика",
        //   text:
        //     "Получишь опыт взаимодействия с крупными компаниями по внедрению твоего проекта в их бизнес",
        // },
        // {
        //   icon: "6",
        //   title: "Создание<br>своего бизнеса",
        //   text:
        //     "После первого успешного внедрения - самое время рассказать о своем продукте всему миру!",
        // },
        {
          icon: "5",
          title: "Легкое<br>трудоустройство",
          text: "Сможешь найти работу в фирме заказчика или партнёра.",
        },
        // {
        //   icon: "8",
        //   title: "Финансы для<br>твоей идеи",
        //   text:
        //     "Ты получишь поддержку в вопросах финансирования твоей идеи через гранты и другие виды субсидий.",
        // },
      ],

      openBurgerMenu: false,
      showRequestModal: false,
      showRegistrationModal: false,
      setTypeForModal: "",

      AnimationStart: 0.5,
      AnimationBonusTime: 0.25,

      Statistics_Loaded: {
        UsersRegistered: 400,
        ClosedPractics: 50,
        WorkInForus: 6,
        OurPartners: 40,
      },
      Statistics_Showed: {
        UsersRegistered: 0,
        ClosedPractics: 0,
        WorkInForus: 0,
        OurPartners: 0,
      },
      Statistic_Height: null,
      Statistic_Was_Showed: false,
    };
  },
  async mounted() {
    let _users_counter_resp = await getRegisteredUsersCounter();
    let _last_statistic = _users_counter_resp.data;
    let roundToNearestTen = (number) => {
      return Math.floor(number / 10) * 10;
    };
    let _users_counter = roundToNearestTen(_last_statistic.users);
    let _companies_counter = roundToNearestTen(_last_statistic.companies);
    this.Statistics_Loaded.UsersRegistered = _users_counter;
    this.Statistics_Loaded.OurPartners = _companies_counter;

    let users_numbers_start_from =
      _users_counter > 80 ? _users_counter - 80 : 0;

    let DynamicNumbers = document.querySelector("#OctagonToday > .OTBlocks");
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (
          typeof getCurrentAnimationPreference === "function" &&
          !getCurrentAnimationPreference()
        ) {
          return;
        }

        if (entry.isIntersecting && !this.Statistic_Was_Showed) {
          this.Statistic_Was_Showed = true;
          this.AnimateNumbers(
            users_numbers_start_from,
            this.Statistics_Loaded.UsersRegistered,
            "UsersRegistered"
          );
          this.AnimateNumbers(
            0,
            this.Statistics_Loaded.ClosedPractics,
            "ClosedPractics"
          );
          this.AnimateNumbers(
            0,
            this.Statistics_Loaded.WorkInForus,
            "WorkInForus"
          );
          this.AnimateNumbers(
            0,
            this.Statistics_Loaded.OurPartners,
            "OurPartners"
          );
        }
      });
    });

    observer.observe(DynamicNumbers);
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  methods: {
    showPage() {
      // Navbar
      gsap.fromTo(
        ".Navbar",
        {
          y: -100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,

          duration: 2,
          delay: this.AnimationStart,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );

      // Block1
      gsap.fromTo(
        ".ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock > .BackgroundImages",
        {
          opacity: 0,
          y: -100,
        },
        {
          opacity: 1,
          y: 0,

          duration: 2,
          delay: this.AnimationStart + this.AnimationBonusTime * 3,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );
      gsap.fromTo(
        ".ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock > *:not(.BackgroundImages)",
        {
          opacity: 0,
          x: -200,
        },
        {
          opacity: 1,
          x: 0,

          duration: 2,
          delay: this.AnimationStart + this.AnimationBonusTime * 3,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );

      // Block2 Text
      gsap.fromTo(
        ".ContentBlocks > .ContentBlock:nth-child(2) > .ContentTitle",
        {
          opacity: 0,
        },
        {
          opacity: 1,

          duration: 2,
          delay: this.AnimationStart + this.AnimationBonusTime * 4,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );

      // Footer Buttons
      gsap.fromTo(
        ".ContentBlocks > .ContentBlock:nth-child(3)",
        {
          opacity: 0,
        },
        {
          opacity: 1,

          duration: 2,
          delay: this.AnimationStart + this.AnimationBonusTime * 5,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );
      gsap.fromTo(
        ".ContentBlocks > .ContentBlock:nth-child(3) > *:nth-child(1)",
        {
          opacity: 0,
        },
        {
          opacity: 1,

          duration: 2,
          delay: this.AnimationStart + this.AnimationBonusTime * 8,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );
      gsap.fromTo(
        ".ContentBlocks > .ContentBlock:nth-child(3) > *:nth-child(2)",
        {
          opacity: 0,
        },
        {
          opacity: 1,

          duration: 2,
          delay: this.AnimationStart + this.AnimationBonusTime * 10,
          ease: "power3.inOut",
          stagger: 0.1,
        }
      );
    },

    sendFalseToParent() {
      this.showRequestModal = false;
    },
    sendCloseRegistrationModal() {
      this.showRegistrationModal = false;
    },
    openOrgRegistration(type) {
      this.setTypeForModal = type;
      this.showRegistrationModal = false;
      this.showRequestModal = true;
    },

    smoothNumbersScript() {
      /*
      const time = 10000;
      const step = 1;
      const num = 100;


      function animate(n) {
        let addTime = num - n <= 5 ? 50 + (5 - (num-n)) * 50 : 0
        setTimeout(() => {
          let e = document.querySelector("#out");
          e.innerHTML = n;
          if (n == num) {
              return 0;
            }
          animate(n + 1);
        }, 100 - (num - n) + addTime);
      }

      animate(0);
      */
    },

    AnimateNumbers(n, max, __name) {
      let _addTime = 30;
      let SlowWhenNumber = 8;
      let addTime =
        max - n <= SlowWhenNumber
          ? _addTime + (SlowWhenNumber - (max - n)) * _addTime
          : 0;
      setTimeout(() => {
        this.Statistics_Showed[__name] = n;
        if (n == max) {
          return 0;
        }
        this.AnimateNumbers(n + 1, max, __name);
      }, 30 - (max - n) + addTime);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

#LandingWrapper {
  position: relative;

  left: 0%;
  top: 0%;

  margin: 0%;
  padding: 0%;

  width: 100%;
  height: auto;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );

  overflow-x: hidden;
}

#LandingWrapper > .Landing {
  position: relative;

  width: 100%;
  height: auto;
}

.Navbar {
  position: relative;

  width: auto;
  height: auto;

  padding: 38px 117px 106px 117px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Navbar > .NavbarBlock {
  position: relative;

  width: auto;

  display: flex;
  flex-direction: row;
  align-content: center;
}

.Navbar > .NavbarBlock:nth-child(1) > * {
  margin: auto 8.75px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(2),
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(3) {
  margin: auto 28px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(4) {
  margin: auto 53px auto 0px;
}
.Navbar > .NavbarBlock:nth-child(2) > *:nth-child(5) {
  margin: auto 0% auto 0px;
}

.ContentBlock > .ContentAutoBlock {
  position: relative;

  width: auto;
  height: auto;
}
.ContentBlocks > .ContentBlock:nth-child(1) {
  padding: 0% 120px 0% 115px;
  margin-bottom: 165px;
}
.ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
  width: 100%;
  height: 40vw;
}
.ContentBlocks > .ContentBlock:nth-child(3) {
  position: relative;

  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  padding: 61px 0%;

  background-color: rgba(30, 30, 32, 0.5);
}

.ContentBlocks
  > .ContentBlock:nth-child(1)
  > .ContentAutoBlock
  > *:not(.BackgroundImages) {
  z-index: 20;
}

.ContentBlock > .ContentTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  font-family: "Montserrat";

  margin-bottom: 59px;

  /* or 117% */
  text-align: center;

  color: #ffffff;
}

.Benefits {
  position: relative;

  width: 100%;
  height: auto;

  padding: 0% 40px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ContentBlocks
  > .ContentBlock:nth-child(1)
  > .ContentAutoBlock
  > .ButtonFooterGradientInside {
  margin-top: 29px;
  margin: auto auto auto 0% !important;
}

.ContentBlocks > .ContentBlock:nth-child(3) > * {
  margin: 0% 34px !important;
}

#MobileNavbar {
  position: relative;

  width: 100%;
  height: 53px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6vw;
}

#ImgBlur1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 1432px;
  background-image: url("./../assets/img/Landing/Figure1.svg");
  filter: blur(150px);
}
#ImgBlur2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 100% 10%;
  background-repeat: no-repeat;
  background-size: 1618px;
  background-image: url("./../assets/img/Landing/Figure2.svg");
  filter: blur(150px);
}
#ImgBlur3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 850px;
  background-image: url("./../assets/img/Landing/Figure3.svg");
  filter: blur(150px);
}
#ImgBlur4 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 850px;
  background-image: url("./../assets/img/Landing/Figure4.svg");
  filter: blur(150px);
}

#ImgLogo {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + (32vw) / 1.8) calc(50%);
  background-repeat: no-repeat;
  background-size: 32vw;
  background-image: url("./../assets/img/Landing/HeaderFigures/Logo2.svg");
}
#ImgLogoRectangle {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + (32vw) / 1.8) calc(50%);
  background-repeat: no-repeat;
  background-size: 32vw;
  background-image: url("./../assets/img/Landing/HeaderFigures/Rectangle.svg");
}

#ImgLine {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: 50% calc(50% - 14vw);
  background-repeat: no-repeat;
  background-size: 5vw auto;
  background-image: url("./../assets/img/Landing/HeaderFigures/Line.svg");
}

#ImgCircle {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + 32vw) calc(50% - 18vw);
  background-size: 2.5vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/Circle.svg");
}
#ImgCircleDotted {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + 32.6vw) calc(50% - 16.8vw);
  background-size: 2.5vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/CircleDotted.svg");
}

#ImgTriangle {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% - 2vw) calc(50% + 14.5vw);
  background-size: 2.6vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/Triangle.svg");
}
#ImgTriangleDotted {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% - 1.5vw) calc(50% + 15.5vw);
  background-size: 2.6vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/TriangleDotted.svg");
}

#ImgPlus {
  position: absolute;
  width: 100%;
  height: 100%;

  background-position: calc(50% + 34vw) calc(50% + 12vw);
  background-size: 2vw;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/Landing/HeaderFigures/Plus.svg");
}

.FlexBlock {
  display: flex;
}
.Flex-H {
  flex-direction: row;
}
.Flex-V {
  flex-direction: column;
}

.OctagonTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 140%;

  color: #ffffff;
}
.OctagonLogo {
  position: relative;

  width: 12vw;
  height: 4vw;

  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: auto 115%;
  background-image: url("./../assets/img/Landing/NewOctagonLogo.svg");

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.Navbar > .NavbarBlock > .ButtonLinkSelected,
.BurgerMenuButtons .ButtonLinkSelected {
  color: rgba(83, 185, 234, 0.7);
}
.ButtonLink {
  text-decoration: none;

  color: rgba(255, 255, 255, 0.7);

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.ButtonFooter {
  text-decoration: none;

  width: 290px;
  height: 58px;

  margin: 0% 34px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #ffffff;
}
.ButtonFooterGradient {
  position: relative;

  width: 290px;
  height: 58px;

  padding: 16px;
  text-align: center;

  color: #ffffff;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}
.ButtonFooterGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ButtonFooterGradientInside {
  position: relative;

  width: 290px;
  height: 58px;

  padding: 16px;
  text-align: center;

  color: #ffffff;

  border: 0px solid;
  backdrop-filter: blur(1px);
  border-radius: 4px;

  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

.ButtonGradient {
  position: relative;

  width: 210px;
  height: 40px;

  padding: 9px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 22px;

  text-align: center;

  color: #ffffff;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(1px);
}
.ButtonGradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ContentHeader {
  position: relative;
  margin-top: 8vw;
  margin-bottom: 23px;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  line-height: 79px;
  font-family: "Montserrat";

  color: #ffffff;
}
.ContentHeaderList {
  margin-top: 8px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 30px;

  letter-spacing: -0.656526px;

  color: rgba(255, 255, 255, 0.7);
}

.centerButtonText {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.centerButtonText > p {
  position: relative;
  margin: auto 0px auto auto;
}
.centerButtonText > img {
  position: relative;

  width: 12px;
  height: 12px;

  margin: auto auto auto 14px;
}

#MobileNavbar {
  position: relative;

  width: auto;
  height: 100%;

  margin: auto;

  display: none;
  z-index: 1001;
}
#MobileNavbar > i {
  position: relative;
  margin: auto auto auto 10px;
}

.ContentFooter {
  position: relative;

  display: flex;
  justify-content: center;

  padding: 60px 0%;
  background: rgba(30, 30, 32, 0.5);
}
</style>

<style scoped>
@media (max-width: 479px) {
  .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > .ButtonGetProjectList {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 3rem 10% 0px 10%;
  }

  .LandingBackgroundBlur {
    display: none;
  }

  .UseThreeBlockPerLine {
    width: 65vw !important;
  }

  .Navbar {
    position: fixed;

    width: 100%;

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    background-color: rgb(20, 20, 20);

    z-index: 100;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:not(#MobileNavbar) {
    display: none;
  }
  .Navbar > .NavbarBlock:nth-child(2) > #MobileNavbar {
    display: flex;
    color: #e0e0e0;
  }

  .OctagonLogo {
    width: 80vw;
    height: 10vw;
  }

  .ContentBlocks {
    padding-top: 100px;
  }

  .Landing > .ContentBlocks {
    display: flex;
    flex-direction: column;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 0% 4vw;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
    position: relative;

    width: 100%;
    height: auto;

    display: flex;

    flex-direction: column;
    justify-content: center;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p {
    position: relative;
    width: auto;

    padding-top: 100vw;

    text-align: center;

    font-size: 8vw;
    line-height: 10vw;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > ul {
    position: relative;
    width: auto;
    font-size: 4vw;
    line-height: 6vw;
  }
  .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > .ButtonFooterGradientInside {
    margin: 8vw auto auto auto !important;
  }

  .Landing .ImagesBlock {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }
  .Landing #OctagonToday {
    gap: 30px;
  }
  .Landing #OctagonToday > .OTBlocks {
    flex-direction: column;
  }
  .Landing #OctagonToday > .OTBlocks > * {
    margin-left: auto;
    margin-right: auto;

    width: 200px;
  }
  .Landing #OctagonToday > .OTBlocks > * > * {
    text-align: center;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBHead {
    font-size: 44px;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBText {
    font-size: 16px;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 110px;
    background-size: contain;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) > .ContentTitle,
  .ContentBlocks > .ContentBlock > .ContentTitle {
    position: relative;
    width: auto;
    font-size: 8vw;
    line-height: 10vw;

    justify-content: center;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(3) {
    padding: 10vw 0%;

    position: relative;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .Landing .ButtonFooterGradientInside,
  .Landing .ButtonFooterGradient {
    width: auto;
    height: auto;

    margin: 4vw 0%;

    font-size: 4vw;
    line-height: 6vw;

    padding: 4vw 6vw;
  }

  .Landing .Benefits {
    padding: 0%;
  }

  #ImgLogo {
    background-position: calc(50%) calc(12vw);
    background-size: 76vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50%) calc(10vw);
    background-size: 76vw;
  }

  #ImgLine {
    background-position: calc(50% - 40vw) calc(14vw);
    background-size: 10vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 37vw) calc(6vw);
    background-size: 5vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 38.5vw) calc(8vw);
    background-size: 5vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 36vw) calc(82vw);
    background-size: 5vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 35vw) calc(84vw);
    background-size: 5vw;
  }

  #ImgPlus {
    background-position: calc(50% + 34vw) calc(80vw);
    background-size: 5vw;
  }

  .ContentBlocks > .ContentBlock:nth-child(3) > * {
    margin: 3vw 34px !important;
  }

  #LandingWrapper > .ContentFooter {
    flex-direction: column;
  }
  #LandingWrapper > .ContentFooter > * {
    margin: 2vw 35px !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .UseThreeBlockPerLine {
    width: 65vw !important;
  }

  .LandingBackgroundBlur {
    display: block;
  }

  .Navbar {
    position: fixed;

    width: 100%;

    padding: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    background-color: rgb(20, 20, 20);

    z-index: 100;
  }
  .Navbar > .NavbarBlock:nth-child(2) > *:not(#MobileNavbar) {
    display: none;
  }
  .Navbar > .NavbarBlock:nth-child(2) > #MobileNavbar {
    display: flex;
    color: #e0e0e0;
  }

  .OctagonLogo {
    width: 80vw;
    height: 8vw;
  }

  .ContentBlocks {
    padding-top: 100px;
  }

  .Landing > .ContentBlocks {
    display: flex;
    flex-direction: column;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 0% 6vw;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
    position: relative;

    width: 100%;
    height: auto;

    display: flex;

    flex-direction: column;
    justify-content: center;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p {
    position: relative;
    width: auto;

    padding-top: 100vw;
    margin-bottom: 8vw;

    text-align: center;

    font-size: 7vw;
    line-height: 9vw;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > ul {
    position: relative;
    width: auto;
    font-size: 4.5vw;
    line-height: 7vw;
  }
  .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > .ButtonFooterGradientInside {
    margin: 8vw auto auto auto !important;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) {
    margin-bottom: 15vw;
  }

  .Landing .ImagesBlock {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
  }
  .Landing #OctagonToday > .OTBlocks {
    flex-direction: column;
  }
  .Landing #OctagonToday > .OTBlocks > * {
    margin-left: auto;
    margin-right: auto;

    width: 200px;
  }
  .Landing #OctagonToday > .OTBlocks > * > * {
    text-align: center;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBHead {
    font-size: 44px;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBText {
    font-size: 16px;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 110px;
    background-size: contain;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) > .ContentTitle,
  .ContentBlocks > .ContentBlock > .ContentTitle {
    position: relative;
    width: auto;
    font-size: 7vw;
    line-height: 9vw;

    justify-content: center;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(3) {
    padding: 10vw 0%;

    position: relative;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .Landing .ButtonFooterGradientInside,
  .Landing .ButtonFooterGradient {
    width: auto;
    height: auto;

    margin: 4vw 0%;

    font-size: 4vw;
    line-height: 6vw;

    padding: 4vw 6vw;
  }

  .Landing .Benefits {
    padding: 0%;
  }

  #ImgLogo {
    background-position: calc(50%) calc(12vw);
    background-size: 76vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50%) calc(10vw);
    background-size: 76vw;
  }

  #ImgLine {
    background-position: calc(50% - 40vw) calc(14vw);
    background-size: 10vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 37vw) calc(6vw);
    background-size: 5vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 38.5vw) calc(8vw);
    background-size: 5vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 36vw) calc(82vw);
    background-size: 5vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 35vw) calc(84vw);
    background-size: 5vw;
  }

  #ImgPlus {
    background-position: calc(50% + 34vw) calc(80vw);
    background-size: 5vw;
  }

  .ContentBlocks > .ContentBlock:nth-child(3) > * {
    margin: 3vw 34px !important;
  }

  #LandingWrapper > .ContentFooter {
    flex-direction: column;
  }
  #LandingWrapper > .ContentFooter > * {
    margin: 2vw 35px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .OctagonLogo {
    width: 20vw;
    height: 3vw;
  }

  .Navbar > .NavbarBlock:nth-child(2) > a {
    font-size: 1.4vw;
    font-weight: 1.4vw;

    margin: auto 1vw !important;
  }

  .ContentBlocks {
    padding-top: 4vw;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 2vw 6vw;
  }

  .Navbar {
    padding: 6vw;
  }
  .Navbar > .NavbarBlock:nth-child(2) {
    height: auto;
    margin: auto 0%;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p {
    font-size: 4vw;
    line-height: 4vw;
    margin-top: 4vw;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > ul {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #ImgLogo {
    background-position: calc(50% + (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50% + (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }

  #ImgLine {
    background-position: 50% calc(50% - 18vw);
    background-size: 5vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 40vw) calc(50% - 18vw);
    background-size: 3vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 40.6vw) calc(50% - 16.8vw);
    background-size: 3vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 3vw) calc(50% + 14.5vw);
    background-size: 2.6vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 2.5vw) calc(50% + 15.5vw);
    background-size: 2.6vw;
  }

  #ImgPlus {
    background-position: calc(50% + 42vw) calc(50% + 18vw);
    background-size: 2.4vw;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) {
    margin-bottom: 2vw;
  }

  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBHead {
    font-size: 44px;
  }
  .Landing #OctagonToday > .OTBlocks > .OTBlock > .OTBText {
    font-size: 16px;
  }
  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 110px;
    background-size: contain;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) > .ContentTitle,
  .ContentBlocks > .ContentBlock > .ContentTitle {
    position: relative;
    width: auto;
    font-size: 4vw;
    line-height: 4vw;

    justify-content: center;
  }

  .Benefits {
    padding: 0% 0%;
    margin: auto;
    gap: 1vw;
  }

  #LandingWrapper > .ContentFooter > * {
    margin: 0% 35px !important;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .OctagonLogo {
    width: 25vw !important;
    height: 4vw !important;
  }

  .ContentBlocks {
    padding-top: 4vw;
  }
  .Landing > .ContentBlocks > .ContentBlock {
    padding: 2vw 6vw;
  }

  .Navbar {
    padding: 6vw;
  }
  .Navbar > .NavbarBlock:nth-child(2) {
    height: auto;
    margin: auto 0%;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p {
    font-size: 4vw;
    line-height: 4vw;
    margin-top: 4vw;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > ul {
    font-size: 2vw;
    line-height: 2.5vw;
  }

  #ImgLogo {
    background-position: calc(50% + (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }
  #ImgLogoRectangle {
    background-position: calc(50% + (40vw) / 1.8) calc(50%);
    background-size: 40vw;
  }

  #ImgLine {
    background-position: 50% calc(50% - 18vw);
    background-size: 5vw auto;
  }

  #ImgCircle {
    background-position: calc(50% + 40vw) calc(50% - 18vw);
    background-size: 3vw;
  }
  #ImgCircleDotted {
    background-position: calc(50% + 40.6vw) calc(50% - 16.8vw);
    background-size: 3vw;
  }

  #ImgTriangle {
    background-position: calc(50% - 3vw) calc(50% + 14.5vw);
    background-size: 2.6vw;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 2.5vw) calc(50% + 15.5vw);
    background-size: 2.6vw;
  }

  #ImgPlus {
    background-position: calc(50% + 42vw) calc(50% + 18vw);
    background-size: 2.4vw;
  }

  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) {
    margin-bottom: 2vw;
  }

  .Landing > .ContentBlocks > .ContentBlock .PartnerImagePhoto {
    width: 150px;
    background-size: contain;
  }
  .Landing > .ContentBlocks > .ContentBlock:nth-child(2) > .ContentTitle,
  .ContentBlocks > .ContentBlock > .ContentTitle {
    position: relative;
    width: auto;
    font-size: 4vw;
    line-height: 4vw;

    justify-content: center;
  }

  .Benefits {
    padding: 0% 0%;
    margin: auto;
    gap: 1vw;
  }

  #LandingWrapper > .ContentFooter > * {
    margin: 0% 35px !important;
  }
  .Navbar > .NavbarBlock:nth-child(2) > a {
    font-size: 1.2vw;
    font-weight: 1.2vw;
  }
}

@media (min-width: 1400px) {
  #LandingWrapper {
    position: relative;

    display: flex;
    flex-direction: column;
  }
  #LandingWrapper > .Landing {
    min-width: 1000px;
    max-width: 1400px;

    margin: 0% auto;
  }

  .OctagonLogo {
    width: 294px;
    height: 52.09px;
  }

  .ContentBlocks {
    padding-top: 30px;
  }

  .ContentBlocks > .ContentBlock:nth-child(1) {
    margin-bottom: 0px;
  }

  .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock {
    height: 760px;
  }

  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > p {
    font-size: 54px;
    line-height: 54px;
    margin-top: 100px;
  }
  .Landing
    > .ContentBlocks
    > .ContentBlock:nth-child(1)
    > .ContentAutoBlock
    > ul {
    font-size: 21px;
    line-height: 30px;
  }

  .Landing .ContentBlocks > .ContentBlock:nth-child(1) > .ContentAutoBlock > a {
    margin: 40px auto auto 0% !important;
  }

  #ImgLogo {
    background-position: calc(50% + (521px) / 1.8) calc(50px);
    background-size: 521px;
  }
  #ImgLogoRectangle {
    background-position: calc(50% + (521px) / 1.8) calc(30px);
    background-size: 521px;
  }

  #ImgLine {
    background-position: 50% calc(40px);
    background-size: 95px auto;
  }

  #ImgCircle {
    background-position: calc(50% + ((40px) * 11) + 66px) calc(0px);
    background-size: 40px;
  }
  #ImgCircleDotted {
    background-position: calc(50% + ((40px) * 11) + 86px) calc(16px);
    background-size: 40px;
  }

  #ImgTriangle {
    background-position: calc(50% - 20px) calc(500px);
    background-size: 73px;
  }
  #ImgTriangleDotted {
    background-position: calc(50% - 10px) calc(530px);
    background-size: 73px;
  }

  #ImgPlus {
    background-position: calc(50% + ((40px) * 11) + 66px) calc(530px);
    background-size: 33.3px;
  }

  .ContentBlocks > .ContentBlock:nth-child(3) {
    background-color: transparent;

    padding: 60px 0% 120px 0%;
  }

  #ImgBlur1 {
    background-size: 1150px;
  }
  #ImgBlur2 {
    background-position: 100% 0px;
    background-size: 1250px;
  }
  #ImgBlur3 {
    background-size: 550px;
  }
  #ImgBlur4 {
    background-size: 850px;
  }
}
</style>

<style scoped>
.BurgerMenuButtons {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: auto;

  padding: 20px 20px 12vw 20px;

  background-color: rgb(20, 20, 20);
}

/* .BurgerMenuButtons > * {

  margin: 4vw auto !important;
} */

.BurgerMenuButtons > div.OctagonLogo {
  margin: 4vw auto 8vw auto !important;
}

.BurgerMenuButtons > a.ButtonLink {
  margin: 1vw auto !important;
  font-size: 4vw;
}

.BurgerMenuButtons a.ButtonLink:nth-child(4) {
  margin-bottom: 8vw !important;
}

.BurgerMenuButtons a.ButtonFooter {
  margin: 2vw auto !important;
  width: 60vw !important;
}
</style>

<style scoped>
.PartnerImagePhoto {
  position: relative;
  display: block;

  width: 200px;
  height: auto;

  aspect-ratio: 1 / 0.675;
}

.PI_1C {
  background: url("./../assets/img/Landing/PartnersImages/1C.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_MOIO {
  background: url("./../assets/img/Landing/PartnersImages/minobr_irk_obl.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_IRNITU {
  background: url("./../assets/img/Landing/PartnersImages/irnitu.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_MICCIFRI {
  background: url("./../assets/img/Landing/PartnersImages/mincifri.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.PI_OBLASTMOLODIH {
  background: url("./../assets/img/Landing/PartnersImages/oblast_molodih.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

#PartnersBlock {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 90px 0%;

  margin-bottom: 100px;

  background-color: white;
}
#PartnersBlock > .FullWidthBlock {
  position: absolute;

  width: 100vw;
  height: 100%;

  margin: 0% !important;

  top: 0%;
  left: calc((100vw - 100%) * -1 / 2);

  background-color: white;

  z-index: 1;
}

#PartnersBlock > * {
  z-index: 2;
}

#PartnersBlock > p {
  color: #1d1a42;
}

.ImagesBlock {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#OctagonToday {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  padding: 90px 0%;

  margin-bottom: 100px;
}
#OctagonToday > .OTBlocks {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 50px;
}
#OctagonToday > .OTBlocks > .OTBlock {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
#OctagonToday > .OTBlocks > .OTBlock > .OTBHead {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;

  color: #fff585;
  font-family: "Montserrat";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 62.5% */
  text-align: center;
}
#OctagonToday > .OTBlocks > .OTBlock > .OTBText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  text-align: center;
}
</style>
